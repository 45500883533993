.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  font-family: 'Dancing Script', cursive;
  font-size: clamp(5px, 3vw, 30px);
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
}

.phone {
  color: white;
  font-size: 2.5vw;
  margin-right: 10px;
}

.header a {
  color: white;
  text-decoration: none;
  font-size: 2.5vw;
  margin-right: 10px;
}

.header-left,
.header-center,
.header-right {
  display: flex;
  justify-content: space-between;
}

.header-left .logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-center {
  margin-left: 70px !important;
}

.header-center .contact {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-right .realisations {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-left .logo .icon,
.header-center .contact .icon,
.header-right .realisations .icon {
  margin-right: 10px;
}

.header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgb(76, 248, 222);
}

@media (max-width: 767px) {
  .header {
    flex-direction: row;
    min-height: auto;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .phone,
  .header a {
    font-size: 3.5vw;
    margin-right: 0;
  }

  .header .logo .icon,
  .header .contact .icon,
  .header .realisations .icon {
    margin-right: 5px;
  }

  .header-left {
    flex-grow: 1;
  }

  .header-right {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }
}