@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.footer-links-container {
    background-color: rgb(76, 248, 222);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    font-family: 'Dancing Script', cursive;
}

.footer-links {
    display: flex;
    justify-content: center;
    height: 70px;
}
.footer-link {
    color: white;
    text-decoration: none;
    margin: 0 1%; 
    margin-top: 15px;
    margin-right: 5%; 
    margin-left: 5%; 
    font-size: clamp(14px, 3vw, 25px); 
    white-space: nowrap; 
}

body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }